import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import group from "../../images/mobile-app-developmemt/Group.png"
import * as styles from "./FeatureMobile.module.scss"

const MobileApplication = ({ strapiData }) => {
  return (
    <div className={`${styles.mobileApplication}`}>
      <Container>
        <h2
          className={styles.heading}
          dangerouslySetInnerHTML={{ __html: strapiData?.title }}
        />
        <div
          className={styles.description}
          dangerouslySetInnerHTML={{
            __html: strapiData?.subTitle,
          }}
        />
        <Row className={`gap-30 ${styles.cardWrapper}`}>
          {strapiData?.cards.map((e, i) => (
            <Col lg={6} md={6} xs={12} key={i} className="d-flex">
              <div className={styles.card}>
                <div className="d-flex justify-content-between">
                  <lottie-player
                    className={styles.cardImg}
                    autoplay
                    loop
                    src={e?.image1[0]?.localFile?.publicURL}
                    style={{ height: "60px", width: "60px" , marginBottom : "30px"}}
                  />
                </div>

                <h3>{e?.title}</h3>
                <p>{e?.subTitle}</p>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
      <div className={styles.choseImg}>
        <img decoding="async" alt="reactangle" loading="lazy" src={group} />
      </div>
    </div>
  )
}

export default MobileApplication
