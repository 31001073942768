// extracted by mini-css-extract-plugin
export var Heading = "Banner-module--Heading--7dc23";
export var bannerCon = "Banner-module--bannerCon--0b862";
export var bannerHeading = "Banner-module--bannerHeading--815be";
export var btn = "Banner-module--btn--5d3c3";
export var cardWidth = "Banner-module--cardWidth--ca7b4";
export var cards = "Banner-module--cards--84085";
export var imageAnimation = "Banner-module--imageAnimation--80975";
export var portfolioAppWorkBanner = "Banner-module--portfolioAppWorkBanner--d54ea";
export var subTitle = "Banner-module--subTitle--20a6b";
export var talent = "Banner-module--talent--8b937";
export var text = "Banner-module--text--90d5c";
export var tick = "Banner-module--tick--f9fa9";
export var topToBottomBounce = "Banner-module--topToBottomBounce--b9d01";
export var vueJsCards = "Banner-module--vueJsCards--35061";