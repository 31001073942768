import React, { useEffect, useRef, useState } from "react"
import * as styles from "./RescueTeam.module.scss"
import Container from "react-bootstrap/Container"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import Modal from "react-bootstrap/Modal"
import ReactPlayer from "react-player"
import { Link } from "gatsby"
import Slider from "react-slick"

function Arrow(props) {
  const { onClick, currentSlide, slideCount, sliderRef } = props
  return (
    <div className={styles.iconContainer}>
      <div
        className={styles.portfolioArrowRightIconCover}
        onClick={() => {
          if (currentSlide === slideCount - 1) {
            sliderRef?.slickGoTo(0) // Reset to first slide
          } else {
            onClick() // Move to next slide
          }
        }}
      >
        <img
          decoding="async"
          loading="lazy"
          alt="arrow"
          src={
            "https://invozone-backend.s3.us-east-1.amazonaws.com/right_f5df90e3bd.svg"
          }
          className={styles.portfolioArrowIcon}
        />
      </div>
    </div>
  )
}

function PrevArrow(props) {
  const { onClick, currentSlide, slideCount, sliderRef } = props
  return (
    <div className={styles.iconContainerLeft}>
      <div
        className={styles.portfolioArrowIconCover}
        onClick={() => {
          if (currentSlide === 0) {
            sliderRef?.slickGoTo(slideCount - 1) // Go to last slide if at the first
          } else {
            onClick() // Move to previous slide
          }
        }}
      >
        <img
          decoding="async"
          loading="lazy"
          alt="arrow"
          src={
            "https://invozone-backend.s3.us-east-1.amazonaws.com/left_ccefba5791.svg"
          }
          className={styles.portfolioArrowIcon}
        />
      </div>
    </div>
  )
}

const TrustInvoZone = ({ strapiData, projectEngRTest }) => {
  const [showModal, setShowModal] = useState(false)
  const [currentVideo, setCurrentVideo] = useState("")

  const slide1 = useRef(null)
  const [current, setCurrent] = useState(0)
  const [current_1, setCurrent_1] = useState(0)
  const [current_2, setCurrent_2] = useState(0)
  const [current_3, setCurrent_3] = useState(0)

  useEffect(() => {
    if (
      current === strapiData?.cards.length - 4 ||
      current - 1 === strapiData?.cards.length - 3 ||
      current_2 === strapiData?.cards.length - 2 ||
      current_3 === strapiData?.cards.length - 1
    ) {
      setTimeout(() => {
        slide1?.current?.slickGoTo(0)
      }, 3000)
    }
  }, [current, current_1, current_2, current_3])
  const settings = {
    autoplay: false,
    dots: false,
    slidesToShow: 3,
    arrows: true,
    slidesToScroll: 1,
    prevArrow: (
      <PrevArrow
        currentSlide={current}
        // slideCount={data.length}
        sliderRef={slide1}
      />
    ),
    nextArrow: (
      <Arrow
        currentSlide={current}
        // slideCount={data.length}
        sliderRef={slide1}
      />
    ),
    infinite: false,
    speed: 200,
    afterChange: current => {
      setCurrent(current) // Update current slide index
      if (current === data.length - 1) {
        setTimeout(() => {
          slide1?.current?.slickGoTo(0) // Reset to first slide after reaching the last slide
        }, 3000) // Delay before resetting
      }
    },
    lazyLoad: "ondemand",
    responsive: [
      {
        breakpoint: 1140,
        settings: {
          slidesToShow: 1,
          beforeChange: (current, next) => setCurrent_1(next),
        },
      },
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 1,
          beforeChange: (current, next) => setCurrent_1(next),
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          beforeChange: (current, next) => setCurrent_2(next),
        },
      },
    ],
  }

  const handleVideoPlay = videoUrl => {
    setCurrentVideo(videoUrl)
    setShowModal(true)
  }

  return (
    <div className={styles.Trust}>
      <React.Fragment>
        <Container>
          <h1
            className={`${styles.heading} `}
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          />
          <p
            className={styles.description}
            // dangerouslySetInnerHTML={{
            //   __html: strapiData?.subTitle,
            // }}
          >
            Our software project rescue service is particularly made to target
            your software issues. Here are some of our most prominent software
            rescue services:
          </p>

          <Row className={`gap-30 ${styles.cardWrapper}`}>
            <Slider {...settings} className={styles.SliderWidth} ref={slide1}>
              {strapiData?.cards &&
                strapiData?.cards?.map((e, i) => (
                  <Col xs={12} key={i}>
                    <div className={styles.card}>
                      <img
                        // src={e?.image1[0]?.localFile?.publicURL}
                        src="https://invozone-backend.s3.us-east-1.amazonaws.com/Group_1000007691_d631a4aa94.svg"
                        alt={e?.title}
                        decoding="async"
                        loading="lazy"
                      />
                      <h3>{e?.title}</h3>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: e?.subTitle,
                        }}
                        className={styles.cardDec}
                      />
                    </div>
                  </Col>
                ))}
            </Slider>
          </Row>
          <div className={styles.concerns}>
            <Row className="justify-content-between align-items-center">
              <h2
                dangerouslySetInnerHTML={{ __html: strapiData?.subTitle2 }}
              ></h2>

              <div className={styles.cardFooter}>
                <Link
                  to={"/contact-us/"}
                  className="newBtn"
                  style={{ color: "white" }}
                >
                  Explore More
                </Link>
              </div>
            </Row>
          </div>
        </Container>
      </React.Fragment>
    </div>
  )
}

export default TrustInvoZone
