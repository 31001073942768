import React from "react"
import { Col } from "react-bootstrap"
import Container from "react-bootstrap/Container"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import Row from "react-bootstrap/Row"
import * as styles from "./Banner.module.scss"

const Banner = ({ strapiData }) => {
  // const image = getImage(strapiData?.secImages[0]?.localFile)

  return (
    <div className={`${styles.portfolioAppWorkBanner}`}>
      <Container>
        <Row className="align-items-center justify-content-center">
          <Col lg={6}>
            <div className={styles.Heading}>
              <h1
                className={styles.subTitle}
                dangerouslySetInnerHTML={{ __html: strapiData?.title }}
              ></h1>
              <p
                className={`${styles.bannerHeading} `}
                dangerouslySetInnerHTML={{
                  __html: strapiData?.subTitle,
                }}
              ></p>
            </div>
            <div className={`${styles.cards} `}>
              {strapiData?.cards &&
                strapiData?.cards?.map((item, index) => (
                  <div className={`${styles.talent} `} key={index}>
                    <img
                      className={styles.tick}
                      src="https://invozone-backend.s3.us-east-1.amazonaws.com/Frame_2_638748f984.svg"
                      alt={item?.title}
                      style={{
                        height: "27px",
                        width: "27px",
                      }}
                    />
                    <div className={styles.text}>
                      <p>{item?.title}</p>
                    </div>
                  </div>
                ))}
            </div>
            {/* <Link to="/contact-us/" className={styles.btn}>
              Let’s Fix It Together
            </Link> */}
          </Col>
          <Col lg={6}>
            {/* {image ? (
                  <GatsbyImage
                    className={styles.imageAnimation}
                    // placeholder="blurred"
                    decoding="async"
                    image={image}
                    alt={strapiData?.title}
                  />
                ) : (
                  <img
                    className={styles.imageAnimation}
                    src={strapiData?.secImages[0]?.localFile?.publicURL}
                    alt={strapiData?.title}
                    decoding="async"
                  />
                )} */}
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Banner
